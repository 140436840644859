import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

import { setLoading } from '../../action'
import { getEncoders } from '../../apis/Encoder';
import { EncoderCard } from '../../components';
import './style.scss'
class EncoderList extends Component {
  _isMounted = false;

  state = {
    encoders: []
  }


  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    this._isMounted = false;
    this.props.setLoading(true);
    const response = await getEncoders();
    if (response)
      this.setState({ encoders: response || [] });
    this.props.setLoading(false);
  }

  onUpdate = async () => {
    this._isMounted = true;
    await this.propsToState();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { encoders } = this.state;
    return (
      <div className="encoder-list">
        {
          encoders && encoders.map(encoder => (
            <EncoderCard encoder={encoder} key={`${encoder._id}-${!!encoder.config}`} onDelete={this.onUpdate} />
          ))
        }
      </div>
    )
  }

}

EncoderList.propTypes = {

}
const mapStateToProps = ({ overloading }) => ({
  overloading
});
export default withRouter(connect(mapStateToProps, {
  setLoading
})(EncoderList));