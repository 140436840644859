const ThemeColors = {
  primary: {
    light: '#0065c1',
    main: '#357EDD',
    dark: '#5a97fa',
    contrastText: '#fff',
  },
  secondary: {
    light: '#3bb36a',
    main: '#0B9852',
    dark: '#007d39',
    contrastText: '#fff',
  },
}

export default ThemeColors;

export const styles = {
  '--panda-primary-light': ThemeColors.primary.light,
  '--panda-primary-dark': ThemeColors.primary.dark,
  '--panda-primary-main': ThemeColors.primary.main,
  '--panda-primary-contrastText': ThemeColors.primary.contrastText,
  '--panda-secondary-light': ThemeColors.secondary.light,
  '--panda-secondary-dark': ThemeColors.secondary.dark,
  '--panda-secondary-main': ThemeColors.secondary.main,
  '--panda-secondary-contrastText': ThemeColors.secondary.contrastText
}