import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getUser, getRoles } from '../../apis/Authontication';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@material-ui/core';
import { isEmpty, EmailValidation } from '../../utils';

import './style.scss'

/**
 * Add and edit user info
 * @augments {Component<Props, State>}
 */
class UserManagerDialog extends Component {

  _isMounted = false;

  state = {
    open: false,
    userInfo: {},
    roles: []
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    const { open, userId } = props;
    if (open) {
      const userInfo = (userId && open) ? await getUser(userId) : {};
      const roles = await getRoles() || [];
      this._isMounted && this.setState({ open, userInfo: userInfo || {}, roles });
    } else {
      this._isMounted && this.setState({ open });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSave = () => this.props.onSave && this.props.onSave(this.state.userInfo);
  onClose = () => this.props.onClose && this.props.onClose();

  onChangeUserInfo = field => (evt, newValue) => {
    const { userInfo } = this.state;
    userInfo[field] = evt.target.value;
    this.setState({ userInfo });
  }

  render() {
    const { open, userInfo, roles } = this.state;
    return (
      <Dialog open={open} className="panda-edit-user-dialog" fullWidth>
        <DialogTitle>{userInfo._id ? 'Edit user' : 'Add user'}</DialogTitle>
        <DialogContent>
          <div className="container">
            {
              userInfo._id && <TextField value={userInfo._id} label="User Id" />
            }
            <TextField
              label="First name"
              value={userInfo.firstName || ''}
              onChange={this.onChangeUserInfo('firstName')}
              error={isEmpty(userInfo.firstName)}
            />
            <TextField
              label="Last name"
              value={userInfo.lastName || ''}
              onChange={this.onChangeUserInfo('lastName')}
              error={isEmpty(userInfo.lastName)}
            />
            <TextField
              label="Email"
              value={userInfo.email || ''}
              onChange={this.onChangeUserInfo('email')}
              error={EmailValidation(userInfo.emial)}
            />
            {
              !userInfo._id &&
              <TextField
                label="Password"
                value={userInfo.password || ''}
                onChange={this.onChangeUserInfo('password')}
                error={isEmpty(userInfo.password)}
              />
            }
            <TextField
              select
              label="Role"
              value={userInfo.mode || ''}
              onChange={this.onChangeUserInfo('mode')}
            >
              {
                roles.map(role => <MenuItem value={role._id} key={role._id}>{role.name}</MenuItem>)
              }

            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={this.onSave}>{userInfo._id ? 'Edit user' : 'Add user'}</Button>
          <Button variant="contained" color="primary" onClick={this.onClose}>{'close'}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UserManagerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func
}

export default UserManagerDialog