import { alertActions } from "../types"

export const showAlert = (title = '', content = '', onAction = () => { }) => dispatch => {
  dispatch({
    type: alertActions.SHOW_ALERT,
    payload: { title, content, onAction }
  })
}

export const hideAlert = () => dispatch => {
  dispatch({
    type: alertActions.HIDE_ALERT,
  })
}