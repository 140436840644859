import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Grid, TextField, MenuItem, AppBar, Tabs, Tab, Paper, Button } from '@material-ui/core';

import VideoStream from './VideoStream';
import AudiioStream from './AudioStream';
import Muxer from './Muxer';
import config from '../../config';
import defaultJson from './default.json'
import { setLoading } from '../../action'
import { updateEncoder, getEncoder } from '../../apis/Encoder';

import './style.scss'
import Output from './Output';
class EditEncoder extends Component {
  _isMounted = false;

  state = {
    tabIndex: 0,
    encoder: {},
    service: {}
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
  }

  propsToState = async (props = this.props) => {
    if (this._isMounted) {
      this._isMounted = false;
      const { id } = props.match.params;
      const encoder = await getEncoder(id);
      console.log(encoder);
      if (!encoder || !encoder._id) props.history.push('/');
      if (!encoder.config) {
        console.log('config')
        this.setState({ service: encoder, encoder: defaultJson })
      } else {
        console.log('no-config')
        this.setState({ service: encoder, encoder: encoder.config })
      }
    }
  }


  changeEncoder = (field, value) => {
    const { encoder } = this.state;
    encoder[field] = value;
    this.setState({ encoder })
  }

  addAndUpdate = async () => {
    const { service, encoder } = this.state;
    delete encoder.muxer['pmt-period'];
    service.config = encoder;
    service.name = encoder.name
    await updateEncoder(service);
    this.props.setLoading(false);
    this.props.history.push('/');
  }

  changeField = field => evt => {
    const { encoder } = this.state;
    encoder[field] = evt.target.value;
    this.setState({ encoder: this.changeLogic(encoder) });
  }

  changeLogic = encoder => {
    const videoFormat = encoder['video-format'] || 'pal';
    const videoStream = encoder['video-stream'] || {};
    const audioStream = encoder['audio-stream'] || {};
    const muxer = encoder['muxer'] || {};
    encoder.name = `encoder${encoder['card-idx']}`
    // const muxer = encoder['muxer'] || {};
    if (['pal', 'ntsc'].includes(videoFormat)) {
      videoStream.width = '720';
      videoStream.profile = 'main';
      videoStream.level = "3.2";
      videoStream['bitrate'] = "1500";
      videoStream['vbv-bufsize'] = "1000";
      videoStream['vbv-maxrate'] = "1500";
      audioStream['bitrate'] = "128";
      muxer['ts-muxrate'] = "1850000";
    } else if (videoFormat.startsWith('720p')) {
      videoStream.width = '1280';
      videoStream.profile = 'high'
      videoStream.level = "4.1";
      videoStream['bitrate'] = "3500";
      videoStream['vbv-bufsize'] = "1000";
      videoStream['vbv-maxrate'] = "3500";
      audioStream['bitrate'] = "192";
      muxer['ts-muxrate'] = "4500000";
    } else {
      videoStream.width = '1920';
      videoStream.level = "4.2";
      videoStream.profile = 'high'
      videoStream['bitrate'] = "4000";
      videoStream['vbv-bufsize'] = "1000";
      videoStream['vbv-maxrate'] = "4000";
      audioStream['bitrate'] = "256";
      muxer['ts-muxrate'] = "4500000";
    }

    const match = /[\d.]+$/g.exec(videoFormat);

    if (match && match.length) {
      const keyInt = Number(match[0]);
      if (keyInt < 29) {
        videoStream['keyint'] = "24";
      } else if (keyInt < 31) {
        videoStream['keyint'] = "30";
      } else {
        videoStream['keyint'] = "50";
      }
    } else {
      videoStream['keyint'] = "24";
    }

    return encoder;
  }

  getRate = type => {
    const { encoder } = this.state;
    const videoStream = encoder['video-stream'] || {};
    const audioStream = encoder['audio-stream'] || {};
    if (type === 'video') {
      const tsMux = Number(encoder.muxer['ts-muxrate']) || 0;
      const audioBitRate = Number(audioStream.bitrate) || 0;
      encoder['video-stream'].bitrate = `${Math.round(tsMux / 1024 / 1.12 - audioBitRate)}`;
    } else if (type === 'audio') {
      const tsMux = Number(encoder.muxer['ts-muxrate']) || 0;
      const videoBitRate = Number(videoStream.bitrate) || 0;
      encoder['audio-stream'].bitrate = `${tsMux / 1024 / 1.12 - videoBitRate}`;
    } else if (type === 'muxer') {
      let value;
      if (encoder.muxer && encoder.muxer['ts-muxrate'])
        encoder.muxer['ts-muxrate'] = `${Math.round((Number(videoStream.bitrate || 0) + Number(audioStream.bitrate || 0)) * 1.12 * 1024)}`;
      // if (value < 2000000) {
      //   encoder.muxer['ts-muxrate'] = 1850000
      // } else if (value > 2000000 && value < 5000000) {
      //   encoder.muxer['ts-muxrate'] = 4500000
      // }
    }
    this.setState({ encoder });
  }



  changeState = field => (evt, newValue) => this.setState({ [field]: newValue })

  changeSubSettings = field => value => this.changeEncoder(field, value);

  render() {
    const { encoder, tabIndex } = this.state;
    const tabs = [
      {
        label: "Video Settings",
        chidren: <VideoStream videoStream={encoder['video-stream'] || {}} videoInput={encoder['video-format']} onChange={this.changeSubSettings('video-stream')} getRate={() => this.getRate('video')} />
      },
      {
        label: "Audio Settings",
        chidren: <AudiioStream audioStream={encoder['audio-stream'] || {}} onChange={this.changeSubSettings('audio-stream')} />
      },
      {
        label: "Mux Settings",
        chidren: <Muxer muxer={encoder['muxer'] || {}} onChange={this.changeSubSettings('muxer')} getRate={() => this.getRate('muxer')} />
      },
      {
        label: "Output Settings",
        chidren: <Output output={encoder['output'] || []} onChange={this.changeSubSettings('output')} />
      }
    ];
    return (
      <div className="panda-edit-encoder">
        <div className="sub-page-header">
          {'Configure Encoder'}
          <Button className="add-button" variant="contained" onClick={this.addAndUpdate}>
            {encoder._id ? 'Save' : 'Save'}
          </Button>
        </div>
        <Grid container spacing={2} component={Paper} className="format-encoder">
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={encoder['name'] || `encoder${encoder['card-idx'] || 0}`}
              onChange={this.changeField('name')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              label="Title"
              value={encoder['title'] || ''}
              onChange={this.changeField('title')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              fullWidth
              type='number'
              label="Card index"
              value={encoder['card-idx'] || 0}
              onChange={this.changeField('card-idx')}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              select fullWidth
              label="Video format"
              value={encoder['video-format'] || ''}
              onChange={this.changeField('video-format')}
            >
              {
                config.videoFormats.map(vf =>
                  <MenuItem key={vf} value={vf}>{vf}</MenuItem>
                )
              }
            </TextField>
          </Grid>
        </Grid>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={this.changeState('tabIndex')} aria-label="simple tabs example">
            {tabs.map((element, index) => (
              <Tab
                key={index}
                className='text-transform-none'
                // icon={element.icon}
                label={element.label} />
            ))}
          </Tabs>
        </AppBar>
        {tabs[tabIndex].chidren}
      </div>
    )
  }
}

EditEncoder.propTypes = {

}
const mapStateToProps = () => ({
});
export default withRouter(connect(mapStateToProps, {
  setLoading
})(EditEncoder));
