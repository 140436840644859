import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Button, IconButton, Icon } from '@material-ui/core'

import { editEncoder, logout, setLoading } from '../../action'

import './style.scss'

/**
 * Video Preview Component
 * @augments {Component<Props, State>}
 */
class Header extends Component {

  dashboard = () => {
    // this.setLoading(true);
    // this.props.history.push('/')
    window.open('/', '_self')
  }
  render() {
    return (
      <div className="admin-header">
        <Button onClick={this.dashboard}>Dashboard</Button>
        <Button onClick={() => this.props.editEncoder()}>Add Encoder</Button>
        <Button onClick={() => this.props.history.push('/dashboard/userManager')}>Managers</Button>
        <Button onClick={() => this.props.history.push('/dashboard/managerEncoder')}>Encoders Management</Button>
        <IconButton className="close-button" onClick={this.props.logout}>
          <Icon>exit_to_app</Icon>
        </IconButton>
      </div>
    )
  }
}
Header.propTypes = {
}

const mapStateToProps = ({ overloading }) => ({
});
export default withRouter(connect(mapStateToProps, {
  editEncoder, logout, setLoading
})(Header));