import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'

import './style.scss'
class Overloading extends Component {
  render() {
    const { overloading } = this.props
    return (
      overloading.loading ?
        <div className="panda-loading-overlay" >
          <div className="loader">
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
            <span className="loader-item"></span>
          </div>
        </div>
        : null
    )
  }
}
const mapStateToProps = ({ overloading }) => ({
  overloading
});
export default withRouter(connect(mapStateToProps, {
})(Overloading));