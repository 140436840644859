/**************************
 * App Redux Action types *
 **************************/

//Auth action types---------------------------------------
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
//---------------------------------------------------------

//Dashboard action types-----------------------
export const OPEN_DASHBOARD = 'OPEN_DASHBOARD';
export const DOCK_DASHBOARD = 'DOCK_DASHBOARD';
//---------------------------------------------

export const SET_OVERLOADING = 'SET_OVERLOADING';

export const encoderActions = {
  SHOW_DIALOG: 'SHOW_DIALOG',
  SET_ENCODER: 'SET_ENCODER'
}

export const alertActions = {
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT'
}