import React, { Component } from 'react'
import { getEncoders, deleteEncoder, updateEncoder, getServiceStatus } from '../../apis/Encoder';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { editEncoder, setEncoder, showAlert } from '../../action'

import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, IconButton, Icon, Tooltip } from '@material-ui/core'

import { AddUserDialog } from '../../components';
import './style.scss'
class ManagerEncoder extends Component {
  _isMounted = false;

  state = {
    encoders: [],
    status: [],
    addUserDialog: false,
    selectedUser: [],
    selectedIndex: -1
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState()
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    if (!this._isMounted) return;
    const encoders = await getEncoders();
    this.setState({ encoders });
    this.getStatus();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  removeEncoder = async id => {
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this Encoder?', async () => {
      await deleteEncoder(id);
      this.propsToState();
    });
  }

  openAddUserDialog = index => {
    this.setState({
      addUserDialog: true,
      selectedIndex: index,
      selectedUser: this.state.encoders[index].users
    })
  }

  addUser = async selectedUser => {
    const { selectedIndex, encoders } = this.state;
    if (selectedIndex !== -1) {
      const encoder = encoders[selectedIndex];
      encoder.users = selectedUser;
      await updateEncoder(encoder);
      this.setState({ addUserDialog: false })
    }

  }

  addConfig = encoder => {
    this.props.history.push(`/dashboard/editEncoder/${encoder._id}`);
  }

  getStatus = async () => {
    const status = [];
    for (const encoder of this.state.encoders) {
      getServiceStatus(encoder._id).then(result => {
        if (result) {
          status.push(encoder._id)
          this.setState({ status })
        }
      })
    }
  }

  render() {
    const { encoders, addUserDialog, selectedUser, status } = this.state;
    return (
      <div className="service-manager">
        <div className="sub-page-header">
          {'Encoders Management'}
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>IP</TableCell>
                <TableCell>port</TableCell>
                <TableCell>email</TableCell>
                <TableCell>status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                encoders.map((encoder, index) => (
                  <TableRow key={encoder._id}>
                    <TableCell>{encoder.name}</TableCell>
                    <TableCell>{encoder.title}</TableCell>
                    <TableCell>{encoder.ip}</TableCell>
                    <TableCell>{encoder.port}</TableCell>
                    <TableCell>{encoder.email}</TableCell>
                    <TableCell className={status.includes(encoder._id) ? 'on-line' : 'off-line'}>
                      {encoder.config ? status.includes(encoder._id) ? 'Online' : 'Offline' : 'Not Configured'}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit Encoder">
                        <IconButton onClick={() => this.props.editEncoder(encoder._id)} color="secondary">
                          <Icon>edit</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Config">
                        <IconButton onClick={() => this.addConfig(encoder)} className="color-danger">
                          <Icon>add</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Users">
                        <IconButton onClick={() => this.openAddUserDialog(index)} className="color-danger">
                          <Icon>group_add</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove Users">
                        <IconButton onClick={() => this.removeEncoder(encoder._id)} className="color-danger">
                          <Icon>delete</Icon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <AddUserDialog
          open={addUserDialog}
          selectedUser={selectedUser}
          onSelect={this.addUser}
          onClose={() => this.setState({ addUserDialog: false })}
        />
      </div>
    )
  }

}

ManagerEncoder.propTypes = {

}

const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer,
});
export default withRouter(connect(mapStateToProps, {
  editEncoder, setEncoder, showAlert
})(ManagerEncoder));