import {
  alertActions
} from '../../../action/types';

//initialize dashboard state-
const INIT_STATE = {
  open: false,
  title: '',
  content: '',
  onAction: () => { }
};
//---------------------------

/**********************
 * Auth user reducers *
 **********************/
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case alertActions.SHOW_ALERT:
      const { title, content, onAction } = action.payload;
      return { open: true, title, content, onAction };

    case alertActions.HIDE_ALERT:
      return { open: false };


    default: return { ...state };
  }
}
