import axios from 'axios';
import AuthHeader from './AuthHeader';
import { ToastsStore } from 'react-toasts'

export const signupApi = async (payload) => {
  return await axios.post(`/api/users/signup`, payload);
}

export const signinApi = async payload => await axios.post('/api/users/login', payload);

export const jwtLoginApi = async () => await axios.post('/api/users/jwtLogin', {}, AuthHeader());
export const getCurrentUser = async () => await axios.get('/api/users/currentUser', {}, AuthHeader());

export const addUserApi = async payload => {
  try {
    const response = await axios.post('/api/users/addUser', payload, AuthHeader());
    return response.data;
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    return null;
  }
}

export const getUsers = async () => {
  try {
    const response = await axios.get('/api/users/getUsers', AuthHeader());
    return response.data;
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    return []
  }
}
export const getUser = async id => {
  try {
    const response = await axios.get(`/api/users/getUser/${id}`, AuthHeader());
    return response.data;
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    return null;
  }
}


export const getRoles = async () => {
  try {
    const response = await axios.get(`/api/users/getRoles`, AuthHeader());
    return response.data;
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    return [];
  }
}

export const updateUser = async userInfo => {
  try {
    const response = await axios.put(`/api/users/updateUser`, userInfo, AuthHeader());
    return response.data;
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    return null;
  }
}

export const removeUser = async id => {
  try {
    const response = await axios.delete(`/api/users/removeUser/${id}`, AuthHeader());
    return response.data;
  } catch (error) {
    error.response && ToastsStore.error(error.response.data.error);
    return null;
  }
}

