import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { getEncoder, addEncoder, updateEncoder } from '../../apis/Encoder';
import { Dialog, DialogActions, DialogContent, Button, Grid, TextField, DialogTitle } from '@material-ui/core';
import { isEmpty } from '../../utils/Validation'
import { closeEncoder, setLoading } from '../../action'
import { ToastsStore } from 'react-toasts';
class EncoderDialog extends Component {
  _isMounted = false;

  state = {
    open: false,
    sended: false,
    encoder: {
      name: 'Encoder',
      apiKey: '20i4Hfre783gdjekqGU7h',
      port: '8000'
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    // if (!this._isMounted) return;
    const { open, encoderId } = props.encoderReducer;
    try {
      if (open) {
        const encoder = encoderId ? await getEncoder(encoderId) || this.state.encoder : this.state.encoder;
        this.setState({ open, encoder });
      } else {
        this.setState({ open })
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeEncoder = field => evt => {
    const disableFields = ['name'];
    if (disableFields.includes(field)) return;
    const { encoder } = this.state;
    encoder[field] = evt.target.value;
    this.setState({ encoder });
  }

  onSave = async () => {
    this.setState({
      sended: true
    });
    const { encoder } = this.state;
    if (isEmpty(encoder.title, encoder.apiKey, encoder.ip, encoder.port)) {
      ToastsStore.error('Please check require field');
      return;
    }
    this.setState({ open: false });
    if (this.state.encoder._id) {
      await updateEncoder(this.state.encoder);
    } else {
      await addEncoder(this.state.encoder);
      this.props.closeEncoder();
    }
  }


  render() {
    const { open, encoder, sended } = this.state;

    const renderEditField = (field, label = field, type = "text", require = false) => (
      <React.Fragment>
        <p className="capitalize m-0 p-0">{`${label}${require ? '*' : ''}`}</p>
        <TextField
          type={type}
          value={encoder[field] || ''}
          onChange={this.changeEncoder(field)}
          error={require && sended && isEmpty(encoder[field])}
        />
      </React.Fragment>
    )
    return (
      <Dialog open={open || false}>
        <DialogTitle>{encoder._id ? 'Edit Encoder' : 'Add Encoder'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6}>{renderEditField('name', 'Name', 'text', true)}</Grid>
            <Grid item md={6}>{renderEditField('title', 'Service Name', 'text', true)}</Grid>
            <Grid item md={12}>{renderEditField('description')}</Grid>
            <Grid item md={12}>{renderEditField('apiKey', 'API key', 'text', true)}</Grid>
            <Grid item md={6}>{renderEditField('ip', 'IP', 'text', true)}</Grid>
            <Grid item md={6}>{renderEditField('port', 'port', 'number', true)}</Grid>
            <Grid item md={6}>{renderEditField('location')}</Grid>
            <Grid item md={6}>{renderEditField('physicalAddress', 'Physical Address')}</Grid>
            <Grid item md={6}>{renderEditField('contactNumber', 'Contact persion number')}</Grid>
            <Grid item md={6}>{renderEditField('email')}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSave}>Save</Button>
          <Button color="secondary" onClick={() => this.props.closeEncoder()}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EncoderDialog.propTypes = {
}
const mapStateToProps = ({ encoderReducer }) => ({
  encoderReducer
});
export default withRouter(connect(mapStateToProps, {
  closeEncoder, setLoading
})(EncoderDialog));