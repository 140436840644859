import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Button, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox } from '@material-ui/core'
import { getUsers } from '../../apis/Authontication';


/**
 * add User dialog
 * @augments {Component<Props, State>}
 */
class AddUserDialog extends Component {
  _isMounted = false;
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    selectedUser: PropTypes.array
  }
  state = {
    open: false,
    selectedUser: [],
    users: []
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    try {
      if (!this._isMounted) return;
      const { open, selectedUser } = props;
      if (open) {
        const users = await getUsers();
        this.setState({ open, users: users || [], selectedUser: selectedUser || [] });
      } else {
        this.setState({ open: false })
      }
    } catch (error) {
      console.log(error);
    }
  }

  selectUser = userId => {
    const { selectedUser } = this.state;
    const index = selectedUser.findIndex(user => user === userId);
    if (index === -1) {
      selectedUser.push(userId);
    } else {
      selectedUser.splice(index, 1);
    }
    this.setState({ selectedUser });
  }

  render() {
    const { users, selectedUser, open } = this.state;
    return (
      <Dialog open={open}>
        <DialogTitle>Select Users</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  users.map(user => (
                    <TableRow key={user._id}>
                      <TableCell>
                        <Checkbox checked={selectedUser.includes(user._id)} onClick={() => this.selectUser(user._id)} />
                      </TableCell>
                      <TableCell>{user.fullName || ''}</TableCell>
                      <TableCell>{user.email || ''}</TableCell>
                      <TableCell>{user.modeInfo && user.modeInfo.name}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onSelect && this.props.onSelect(selectedUser)}>Select</Button>
          <Button onClick={() => this.props.onClose && this.props.onClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AddUserDialog