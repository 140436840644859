import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastsStore } from 'react-toasts';
import { TableContainer, TableHead, TableRow, TableCell, TableBody, Table, IconButton, Icon, Button } from '@material-ui/core'
import { getUsers, updateUser, addUserApi, removeUser } from '../../apis/Authontication';
import UserManagerDialog from '../../components/UserManagerDialog';
import { showAlert } from '../../action'


import './style.scss'
class UserManager extends Component {
  _isMounted = false;

  state = {
    openEditDialog: false,
    editId: '',
    users: []
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.propsToState();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.propsToState(nextProps);
  }

  propsToState = async (props = this.props) => {
    try {
      if (!this._isMounted) return;
      this._isMounted = false;
      const users = await getUsers();
      this.setState({ users });
    } catch (error) {
      console.log(error);
    }
  }

  editUser = editId => {
    this.setState({ editId, openEditDialog: true });
  }
  onCloseDialog = () => this.setState({ openEditDialog: false });

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNewUser = () => {
    this.setState({ editId: '', openEditDialog: true });
  }

  onSave = async userInfo => {
    if (userInfo._id) {
      let result = await updateUser(userInfo);
      console.log(result);
      result && ToastsStore.success('Success update user infomation');
    } else {
      let result = await addUserApi(userInfo);
      result && ToastsStore.success('Success add user ')
    }
    const users = await getUsers();
    this.setState({ users, openEditDialog: false })
  }

  removeUser = async id => {
    this.props.showAlert('Are you sure?', 'Are you sure you want to delete this User?', async () => {
      await removeUser(id);
      ToastsStore.success('Success remove user ')
      const users = await getUsers();
      this.setState({ users, openEditDialog: false })
    });
  }

  render() {
    const { users, editId, openEditDialog } = this.state;
    return (
      <div className="user-manager">
        <div className="sub-page-header">
          {'User Manager'}
          <Button variant="contained" color="primary" onClick={this.addNewUser} className="add-button">Add user</Button>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                users.map(user => (
                  <TableRow key={user._id}>
                    <TableCell>{user.fullName || ''}</TableCell>
                    <TableCell>{user.email || ''}</TableCell>
                    <TableCell>{user.modeInfo && user.modeInfo.name}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => this.editUser(user._id)} color="secondary">
                        <Icon>edit</Icon>
                      </IconButton>
                      <IconButton onClick={() => this.removeUser(user._id)} className="color-danger">
                        <Icon>delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <UserManagerDialog
          open={openEditDialog}
          onClose={this.onCloseDialog}
          onSave={this.onSave}
          userId={editId}
        />
      </div>
    )
  }
}

const mapStateToProps = () => ({
});
export default connect(mapStateToProps, {
  showAlert
})(UserManager);