import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';


import { Overloading } from './components';

import './scss/App.scss';
import Login from './pages/Login';
import Dashboard from './container/Dashboard';
import { styles } from './config/ThemeColors';
import AlertDialog from './components/AlertDialog';
function App() {
  return (
    <div style={styles}>
      <Router>
        <Switch>
          <Route path="/Login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </Router>
      <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
      <Overloading />
      <AlertDialog />
    </div>
  );
}

export default App;
