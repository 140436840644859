/****************
 * App reducers *
 ****************/
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import authUserReducer from './auth'
import encoderReducer from './encoder'
import overloading from './overloading'
import alertDialog from './alertDialog'

const reducers = history => combineReducers({
  router: connectRouter(history),
  authUser: authUserReducer,
  overloading, encoderReducer,
  alertDialog
});

export default reducers;